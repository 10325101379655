import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Router, Route, useLocation } from 'react-router-dom';
// NOTE that you have to do `npm link && npm link @sipgate/botify-chat-widget` before this will work:
import { BotifyChatWidget, BotifyChatWidgetProps } from '@sipgate/botify-chat-widget';

declare global {
    interface Window { mountBotifyChatwidget: any; }
}

const WidgetApp = (props: any) => {

    const location = useLocation();

    return (
        <BotifyChatWidget
            {...props}
            // getToken={async () => accessToken}
            // invalidateToken={async () => console.log('invalidate token!')}
            browserLocation={location}
            // navigateCallbacks={navigateCallbacks}
            // onWidgetEvent={onWidgetEvents}
            // profileAvatar='https://static.sipgate.com/botify/images/avatars/BO-on-black-web.gif'
        />
    );
};

window.mountBotifyChatwidget = function (element: any, props: any) {
    const router = createBrowserRouter([{
        path: '/*',
        element: <WidgetApp {...props} />
    }]);

    createRoot(element).render((
        <RouterProvider router={router} />
    ));
};
